import React,{useState,useEffect} from 'react';
import {BrowserRouter as Router,Switch,Route, Link} from "react-router-dom";
import {Stepper, MobileStepper, Button, Step, StepLabel, Grid} from "@material-ui/core";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import Main from './Main';
import AccountInfo from './AccountInfo';
import ContactInfo from './ContactInfo';
import Password from './Password';
import BusinessInfo from './BusinessInfo';
import InStoreAccount from './InStoreAccount';
import AccountOptions from './AccountOptions';
import CCInfo from './CCInfo';
import ThankYou from './ThankYou';
import initState from './helpers/state';
import "@fontsource/raleway";
import NextButton from './NextButton';
import BackButton from './BackButton';
import { datadogRum } from '@datadog/browser-rum';

function App() {
  if(window.location.search==='?isa=true'){
    initState.inStoreAccount=true;
    initState.inStoreAccountDefault=true;
  }
  const [state,setState] = useState(initState);
  datadogRum.init({
    applicationId: '02d1b9e5-c3b6-40eb-87d6-671d5277ce3b',
    clientToken: 'puba2ed18f1c01a2b0d857378daf6d90f4d',
    site: 'us5.datadoghq.com',
    service: 'signup-app',
    env: 'production',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'allow'
  })
  
  
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function getBackNextButtons(){
    if(window.location.search&&window.location.search.includes('?resume=')&&!state.resume){
      setState(prev=>{
        let ts = {...prev};
        ts.resume=window.location.search.replace(/^\?resume=([^&]*).*$/,"$1");
        return ts;
      })
    }
    if(state.finished){
      return(<div></div>)
    }else{
      return(
        <Grid container spacing={2}>
          <Grid item xs={6} style={{textAlign:'right',marginTop:state.showNar?'3px':''}}><BackButton state={state} setState={setState}/></Grid>
          <Grid item xs={6} style={{textAlign:'left'}}><NextButton state={state} setState={setState}/></Grid>
          <Grid item xs={12} style={{fontSize:"8px",textAlign:"center"}}>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_new">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_new">Terms of Service</a> apply.
         </Grid>
        </Grid>
      )
    }
  }

  function getStepper(){
    let dim = getWindowDimensions();
    if(state.finished){
      return(<div></div>)
    }else{
      if(dim.width>1000){
        return(
            <Stepper activeStep={state.step}>
              {state.path==='Personal'?state.steps.filter(step=>!step.pro).filter(step=>step.label!=='Thank You').map((step,idx)=><Step key={idx}><StepLabel>{step.label}</StepLabel></Step>):state.steps.filter(step=>step.label!=='Thank You').map((step,idx)=><Step key={idx}><StepLabel>{step.label}</StepLabel></Step>)}
            </Stepper>
        );
      }else{
        return(
          <MobileStepper variant="dots" position="static" steps={state.steps.length} activeStep={state.step}
          nextButton={
            <NextButton state={state} setState={setState}/>
          }
          backButton={
            <BackButton state={state} setState={setState}/>
          }>
          {state.path==='Personal'?state.steps.filter(step=>!step.pro).map((step,idx)=><Step key={idx}><StepLabel>{step.label}</StepLabel></Step>):state.steps.map((step,idx)=><Step key={idx}><StepLabel>{step.label}</StepLabel></Step>)}
          </MobileStepper>
        );
      }
    }
  }

  return (
    <Router>
      <Grid container spacing={0} style={{marginBottom:'0',paddingBottom:'0',margin: 0}}>
        <Grid item xs={1}/>
        <Grid item xs={10} >
        {getWindowDimensions().width>1000?(
          <Grid container spacing={2}>
            <Grid item xs={5} style={{textAlign:'right',paddingTop:'18px'}}><img alt="Ring's End Logo" src="RE_logo_red.svg" style={{width:'130px'}}/></Grid>
            <Grid item xs={7}>
              <h1 style={{color:'#192F46'}}>Account Application</h1>
              {process &&process.env&&process.env.NODE_ENV&&process.env.NODE_ENV==='development'?<h6 style={{marginTop:0}}>Development Mode</h6>:''}
            </Grid>
            <Grid item xs={12} style={{marginLeft:'auto',marginRight:'auto'}}>{getStepper()}</Grid>
            {/* <pre>{JSON.stringify(state,null,2)}</pre> */}
          </Grid>
        ):(
          <Grid container spacing={2} style={{marginBottom:'0',paddingBottom:'0'}}>
            <Grid item xs={2} style={{textAlign:'right',paddingTop:'26px'}}><img alt="Ring's End Logo" src="House-Icon-Red.svg" style={{height:'70px'}}/></Grid>
            <Grid item xs={10}><h1 style={{fontSize:'24px',marginLeft:'60px'}}>Account Application</h1></Grid>
          </Grid>
        )}
        </Grid>
        <Grid item xs={1}/>
        <Grid item xs={12} style={{marginTop:'0',paddingTop:'0'}}>
          
          <Switch>

            <Route path="/account-info">
              <AccountInfo state={state} setState={setState}/>
            </Route>

            <Route path="/password">
              <Password state={state} setState={setState}/>
            </Route>

            <Route path="/business-info">
              <BusinessInfo state={state} setState={setState}/>
            </Route>

            <Route path="/in-store-acct">
              <InStoreAccount state={state} setState={setState}/>
            </Route>

            {/* <Route path="/contact-info">
              <ContactInfo state={state} setState={setState}/>
            </Route> */}

            <Route path="/cc-info">
              <CCInfo state={state} setState={setState}/>
            </Route>

            <Route path="/account-options">
              <AccountOptions state={state} setState={setState}/>
            </Route>

            <Route path="/thank-you">
              <ThankYou state={state} setState={setState}/>
            </Route>

            <Route path="/">
              {/* <Main state={state} setState={setState}/> */}
              {/* <PersonalInfo state={state} setState={setState}/> */}
              <ContactInfo state={state} setState={setState}/>
            </Route>

          </Switch>
        </Grid>
        <Grid item xs={1}/>
        <Grid item xs={10}>
          {getWindowDimensions().width<=1000?getStepper():getBackNextButtons()}
        </Grid>
        <Grid item xs={1}/>
      </Grid>
      {/* <pre>{JSON.stringify(state,null,1)}</pre> */}
    </Router>
  );
}

export default App;

import React, {useState,useEffect} from "react";
import {withRouter, Link} from "react-router-dom";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Snackbar, makeStyles, CircularProgress, FormControlLabel, Checkbox} from "@material-ui/core";
import {FindInPageSharp, KeyboardArrowRight} from "@material-ui/icons";
import { isValid } from 'usdl-regex';
import Geocode from "react-geocode";
import ApiHelper from "./helpers/apiHelper";
import crypto from 'crypto';
import { checkApplicationErrors } from "./helpers/errorHelper";
import { datadogRum } from '@datadog/browser-rum';
import { ClientJS } from 'clientjs';

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    left: 10,
    top: 10
  }
});

const markers = [
  {lat:41.0724082, lng:-73.4862019, name: 'Darien', yard:true},
  {lat:41.368615, lng:-73.415738, name: 'Bethel', yard:true},
  {lat:41.212699, lng:-73.51644, name: 'Lewisboro', yard:true},
  {lat:41.325085, lng:-72.194724, name: 'Niantic', yard:true},
  {lat:41.554079, lng:-73.417965, name: 'New Milford', yard:true},
  {lat:41.368016, lng:-72.119287, name: 'New London'},
  {lat:41.177695, lng:-73.416498, name: 'Wilton', yard:true},
  {lat:41.297921, lng:-72.754282, name: 'Branford', yard:true},
  {lat:41.124122, lng:-73.400877, name: 'Norwalk'},
  {lat:41.045625, lng:-73.560825, name: 'Stamford - Harvard Ave'},
  {lat:41.102796, lng:-73.547866, name: 'Stamford - High Ridge'},
  {lat:41.143866, lng:-73.253318, name: 'Fairfield'},
  {lat:41.138282, lng:-73.335497, name: 'Westport'},
  {lat:41.274623, lng:-72.992232, name: 'Orange'},
  {lat:41.420286, lng:-73.419523, name: 'Danbury'},
  {lat:41.244322, lng:-73.19783, name: 'Trumbull'},
  {lat:41.182108, lng:-73.145925, name: 'Stratford'},
  {lat:41.70646, lng:-72.661923, name: 'Wethersfield'},
  {lat:41.282739, lng:-72.59761, name: 'Madison', yard:true}
]

const cache = {};
const stateNames = ["Alabama","Nebraska","Nevada","Arizona","New Hampshire","Arkansas","New Jersey","California","New Mexico","Colorado","New York","Connecticut","North Carolina","Delaware","North Dakota","District of Columbia","Ohio","Florida","Oklahoma","Georgia","Oregon","Pennsylvania","Idaho","Illinois","Rhode Island","Indiana","South Carolina","Iowa","South Dakota","Kansas","Tennessee","Kentucky","Texas","Louisiana","Utah","Maine","Vermont","Maryland","Virginia","Massachusetts","Michigan","Washington","Minnesota","West Virginia","Mississippi","Wisconsin","Missouri","Wyoming","Montana"];
const stateCodes = ["AL","NE","NV","AZ","NH","AR","NJ","CA","NM","CO","NY","CT","NC","DE","ND","DC","OH","FL","OK","GA","OR","PA","ID","IL","RI","IN","SC","IA","SD","KS","TN","KY","TX","LA","UT","ME","VT","MD","VA","MA","MI","WA","MN","WV","MS","WI","MO","WY","MT"];

const pubKey=`-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA2YJ+Ozmbb97S0syvpqRw
gzu4pxI4IwWORYnM1ZAzUoOlpLiAIJ0xiO/Ig2XZjZkxqbnw6rTb25ohdDT9btFW
1sdeEYYHJXOBginuZkU45bYOVXxR5d4AJIXtdsy7R5OmUgLQdBTsA6i7JCQzXkWx
Kc55S70HYZS4PecBitIVIl0FqzsFE3VG2HHrWkNZVT1H0dNgbhVTTFfxBbsJ5lv3
0pNQBTdEi9fRk/nL7F/fbPfC/Uq4wuyh7d45sd9lltbSHE8xYncoV+luFNt0DJEg
bJ8/uNktSJJ/0sag/LZWQKiGUQVaXg30KEdMx6MDtASyBTMtGRxC7Kms13nmB9op
oQIDAQAB
-----END PUBLIC KEY-----`;

function NextButton(props) {
  const classes = useStyles();
  const [showFinishing,setShowFinishing]=useState(false);
  const [showNar,setShowNar]=useState(false); //Whether or not to show not a robot checkbox
  const [narChecked,setNarChecked]=useState(false); //Is the not a robot checkbox checked?

  useEffect(()=>{},[showFinishing])
  useEffect(() => {loadScript("https://www.google.com/recaptcha/api.js?render=6Lfd9eEUAAAAAPIhWvcGZV3gH2jjzeekgd3o7w-z", function(){
    //Do something when script is loaded
  })}, [])

    function setStep(s,d){
      let steps = props.state.steps;
      if(props.state.path==='Personal'){steps=steps.filter(step=>!step.pro).map(step=>step)}
      props.setState(prev=>{
        let ts = {...prev};
        ts.step=s;
        ts.direction=d;
        ts.loc=steps[s].url;
        ts.location.pathname = steps[s].url;
        return ts;
      })
    }

    function getWindowDimensions() {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height
      };
    }

    function loadScript(url, callback){
      let script = document.createElement("script")
      script.type = "text/javascript";
      if (script.readyState){  //IE
          script.onreadystatechange = function(){
              if (script.readyState == "loaded" ||
                      script.readyState == "complete"){
                  script.onreadystatechange = null;
                  callback();
              }
          };
      } else {  //Others
          script.onload = function(){
              callback();
          };
      }
      script.src = url;
      document.getElementsByTagName("body")[0].appendChild(script);
    }

    function checkIfLocal(){
      return new Promise((resolve,reject)=>{
        let derivedAddress = `${props.state.billingAddress}, ${props.state.billingCity}, ${props.state.billingState}, ${props.state.billingZip}`;
        geocodeAddress(derivedAddress).then(
          (response) => {
            let address = {
              street_number:'',
              street:'',
              city:'',
              state:'',
              zip:''
            };
            for (let i = 0; i < response.results[0].address_components.length; i++) {
              for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                switch (response.results[0].address_components[i].types[j]) {
                  case "street_number":
                    address.street_number = response.results[0].address_components[i].long_name;
                    break;
                  case "route":
                      address.street = response.results[0].address_components[i].long_name;
                      break;
                  case "locality":
                    address.city = response.results[0].address_components[i].long_name;
                    break;
                  case "administrative_area_level_1":
                    address.state = response.results[0].address_components[i].long_name;
                    break;
                  case "postal_code":
                    address.zip = response.results[0].address_components[i].long_name;
                    break;
                }
              }
            }
            if(!address.street_number&&!address.street&&!props.state.ignoreAddressError){
              handleChange('showAddressError',true)
              resolve(true)
            }else{
              const { lat, lng } = response.results[0].geometry.location;
              let nearest = getNearest({ lat, lng });
              if(nearest[0].dist>120.701){ //greater-than 75mi
                resolve(false)
              }else{
                resolve(true)
              }
            }
          },
          (error) => {
            console.error(error);
          }
        ); 
      })
    }

    function getNearest(loc){
      let nearest = [];
      let storeOptions = [...markers];
      if(props.state.businessType==='Building Construction'){
        storeOptions = storeOptions.filter(s=>s.yard)
      }
      storeOptions.forEach(m=>{
        let dist = getDistanceFromLatLonInKm(loc.lat,loc.lng,m.lat,m.lng)
        nearest.push({name:m.name,dist})
      })
      nearest.sort((a, b) => (a.dist > b.dist) ? 1 : -1);
      return nearest;
    }

    function geocodeAddress(address){
    
      return new Promise(async (resolve,reject)=>{
        if(cache[address]){
          console.log(`Resolving ${address} from cache`)
          resolve(cache[address])
        }else{
          try{
            //Geocode.setApiKey(process.env.GEOCODE_KEY);
            Geocode.setApiKey('AIzaSyDLTFMt5zvI6Gg9_xNNa-zbAEuPoAzpPnI');
            let response = await Geocode.fromAddress(address);
            cache[address]=response;
            resolve(response);
          }catch(error){
            console.log(`ERROR!:${error}`)
          }
        }
      })
    }

    function handleChange(field,value){
      props.setState(prev=>{
        let ts = {...prev}
        ts[field]=value;
        return ts;
      })
    }

    function errorFree(){
      return new Promise(async(resolve,reject)=>{
        const fieldErrors = checkApplicationErrors(showNar,narChecked,{},props,setStep,stateCodes,stateNames,isValid);
        handleChange('formErrors',fieldErrors);
        if(fieldErrors['FindStores']){alert(`Please find a preferred store`)}
        let noErr = true;
        if(Object.keys(fieldErrors).length){
          noErr=false;
          console.log(fieldErrors)
        }
        resolve(noErr);
      })
    }

    function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      var R = 6371; // Radius of the earth in km
      var dLat = deg2rad(lat2-lat1);  // deg2rad below
      var dLon = deg2rad(lon2-lon1); 
      var a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2)
        ; 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c; // Distance in km
      return d;
    }
    
    function deg2rad(deg) {
      return deg * (Math.PI/180)
    }

    function finish(){
      let ts = {...props.state};
      ts.finished=true;
      if(ts.requestCardOnFile){
        if(ts.ccName && ts.ccName.length){
          ts.ccNameRedacted = `${ts.ccName.replace(/^(....)(.*)$/,"$1**********")}`;
          ts.ccName = crypto.publicEncrypt(pubKey,Buffer.from(ts.ccName,'utf8')).toString('base64');
        }
        if(ts.ccNum&&ts.ccNum.length){
          ts.ccNumRedacted=ts.ccNum.slice(ts.ccNum.length-4);
          ts.ccNum = crypto.publicEncrypt(pubKey,Buffer.from(ts.ccNum,'utf8')).toString('base64');
        }
        if(ts.ccAddress&&ts.ccAddress.length){
          ts.ccAddressRedacted=ts.ccAddress.replace(/^(....)(.*)$/,"$1**********");
          ts.ccAddress = crypto.publicEncrypt(pubKey,Buffer.from(ts.ccAddress,'utf8')).toString('base64');
        }
        if(ts.ccAddress2&&ts.ccAddress2.length){
          ts.ccAddress2Redacted=ts.ccAddress2.replace(/^(..)(.*)$/,"$1**********");
          ts.ccAddress2 = crypto.publicEncrypt(pubKey,Buffer.from(ts.ccAddress2,'utf8')).toString('base64');
        }
        if(ts.ccCity && ts.ccCity.length){
          ts.ccCityRedacted = `${ts.ccCity.replace(/^(...)(.*)$/,"$1**********")}`;
          ts.ccCity = crypto.publicEncrypt(pubKey,Buffer.from(ts.ccCity,'utf8')).toString('base64');
        }
        if(ts.ccState && ts.ccState.length){
          ts.ccStateRedacted = '**';
          ts.ccState = crypto.publicEncrypt(pubKey,Buffer.from(ts.ccState,'utf8')).toString('base64');
        }
        if(ts.ccZip && ts.ccZip.length){
          ts.ccZipRedacted = `*****`;
          ts.ccZip = crypto.publicEncrypt(pubKey,Buffer.from(ts.ccZip,'utf8')).toString('base64');
        }
        if(ts.ccExpiration&&(ts.ccExpiration.length===7||ts.ccExpiration.length===5)){
          ts.ccExpirationRedacted=ts.ccExpiration.slice(3);
          ts.ccExpiration = crypto.publicEncrypt(pubKey,Buffer.from(ts.ccExpiration,'utf8')).toString('base64');
        }
        ts.ccCode = crypto.publicEncrypt(pubKey,Buffer.from(ts.ccCode,'utf8')).toString('base64');
      }
      const client = new ClientJS();
      const visitorInfo={
        browserInfo:'',
        osInfo:'',
        timeZone:'',
        fonts:'',
        fingerprint:''
      };
      visitorInfo.browserInfo=client.getBrowserData();
      visitorInfo.osInfo=client.getOS();
      visitorInfo.timeZone=client.getTimeZone();
      visitorInfo.fonts=client.getFonts();
      visitorInfo.fingerprint=client.getFingerprint();
      ts.visitorInfo=visitorInfo;
      captureInfo(ts)
      .then(captured=>{
        setShowFinishing(false);
        if(captured){
          let steps = props.state.steps;
          setStep(props.state.step+1,'left');
          props.history.push('/thank-you');
          handleChange('finished',true);
        }else{
          alert('Failed to save registration info')
        }
      });
    }

    function captureInfo(data={...props.state}) {
      return new Promise((resolve,reject)=>{
        window.grecaptcha.execute('6Lfd9eEUAAAAAPIhWvcGZV3gH2jjzeekgd3o7w-z', {action: 'submit'}).then(function(token) {
            ApiHelper.grcVerify({rcToken:token})
            .then(grcResp=>{
              //console.log(grcResp.data)
              if(grcResp.data&&grcResp.data.error){
                alert(grcResp.data.error)
                resolve(false)
              }else if(grcResp.data&&grcResp.data.score){
                //alert(`Bot score: ${grcResp.data.score}`)
                if(grcResp.data.score<.5 && !narChecked){
                  setShowNar(true);
                  handleChange('showNar',true);
                  //alert(`Please check the box to continue`)
                  handleChange('formErrors',{nar:`Please confirm you're not a robot`});
                  resolve(false);
                }else{
                  //data.jpc=false;
                  ApiHelper.capture(data)
                  .then(response=>{
                    if(response.data&&response.data.error){
                      alert(response.data.error)
                      resolve(false)
                    }else if(response.data&&response.data.success){
                      resolve(true);
                    }
                  })
                  .catch(error=>{
                    alert(error)
                    resolve(false)
                  })
                }
              }
            })
            .catch(error=>{
              alert(error)
              resolve(false)
            })
        });
      })
    }

    function verifyEmail() {
      ApiHelper.verifyEmail({
        email:props.state.email,
        verifyEmailCode:document.getElementById('emailCode').value
      })
      .then(response=>{
        if(response.data&&response.data.error){
          alert(response.data.error)
        }else if(response.data&&response.data.success){

          datadogRum.setUser({
            name: `${props.state.firstName} ${props.state.lastName}`,
            email: props.state.email
          })
          
          handleChange('emailVerified',true);
          handleChange('showCodeVerify',false);
          let steps = props.state.steps;
          setStep(props.state.step+1,'left');
          props.history.push(steps[props.state.step+1].url);
        }
      })
      .catch(error=>{
        alert(error)
      })
    }
    
    function getNextButton(){
        //let steps = props.state.steps.filter(step=>step.label!=='Thank You');
        let steps = props.state.steps;
        // if(props.state.path==='Personal'){steps=steps.filter(step=>!step.pro).map(step=>step)}
        if(props.state.loc==='/account-options'){
          return(
            <Button variant="contained" size="small" color="primary" onClick={async ()=>{
              errorFree()
              .then(async noErr=>{
                if(noErr){
                  setShowFinishing(true);
                  finish();
                }
              })
            }}>
              Finish
            </Button>
          )
        }else if(props.state.step>=0 && props.state.step < steps.length-1){
          return(
            <React.Fragment>
            <Button size="small"
              onClick={async ()=>{
                errorFree()
                .then(async noErr=>{
                  if(noErr){
                    if(props.state.step===0&&!props.state.ccName){
                      handleChange('ccName',`${props.state.firstName} ${props.state.lastName}`);
                    }
                    if(props.state.step===0&&!props.state.ccEmail){
                      handleChange('ccEmail',`${props.state.email}`);
                    }
                    if(props.state.step===2&&!props.state.ccState){
                      handleChange('ccState',`${props.state.billingState}`);
                    }
                    let capted = await captureInfo();
                    if(props.state.step===0&&!props.state.emailVerified){
                      handleChange('showCodeVerify',true)
                    }else{
                      if(capted){
                        setStep(props.state.step+1,'left');
                        props.history.push(steps[props.state.step+1].url);
                      }
                    }
                  }
                })
              }}
            >
              Next
              <KeyboardArrowRight />
            </Button>
            {showNar?<FormControlLabel
              sx={{ alignItems: 'flex-start' }}
              control={
                <Checkbox
                  sx={{
                    marginTop: -1,
                  }}
                  size="small"
                  checked={narChecked}
                  onChange={(e)=>{
                    setNarChecked(e.target.checked)
                  }}
                  name="narChecked"
                  color="primary"
                  required
                />
              }
              style={{fontSize:'6px'}}
              label="I'm not a robot"
            />:''}
            </React.Fragment>
          )
        }else{
          return(
              <Button size="small"  disabled>
              Next
              <KeyboardArrowRight />
              </Button>
          )
        }
      }

    return(
        <React.Fragment>
        {getNextButton()}
        <Dialog
          open={showFinishing}
          onClose={()=>{
            //handleChange('showFinishing',false)
          }}
        >
          <DialogTitle>Signing you up</DialogTitle>
          <DialogContent style={{textAlign:"center"}}>
            <CircularProgress/>
          </DialogContent>
        </Dialog>
        <Dialog 
          open={props.state.showCodeVerify?(showNar?(narChecked?true:false):true):false}
          onClose={()=>{handleChange('showCodeVerify',false)}}
          aria-labelledby="form-dialog-title"
          classes={getWindowDimensions().width<450?{
            paper: classes.dialog
          }:{}}
        >
        <DialogTitle id="form-dialog-title">Verify Code</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the code you just received via email
          </DialogContentText>
          <TextField
            variant="outlined"
            autoFocus
            margin="dense"
            id="emailCode"
            label="Enter Code"
            type="number"
            inputMode="numeric"
            autoComplete="one-time-code"
            fullWidth
            onKeyUp={(e)=>{if(e.keyCode===13){verifyEmail()}}}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={verifyEmail} color="primary">
            Verify
          </Button>
          <Button variant="contained" onClick={()=>{
            captureInfo();
            handleChange('showCodeResent',true);
          }} color="default">
            Resend
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical:"top", horizontal:"center" }}
        open={props.state.showCodeResent}
        onClose={()=>{handleChange('showCodeResent',false)}}
        message="Code Re-sent"
      />
        </React.Fragment>
    );
};

export default withRouter(NextButton);